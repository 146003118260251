<template>
	<div class="app-container" :style="{direction: yuyan.now=='hanyu'?	'ltr':'rtl'}" style="font-size: 16px;">

		<h2 class="text-center">{{paperData.title}}</h2>
		<p class="text-center" style="color: #666">{{ paperData.createTime }}</p>

		<el-row :gutter="24" style="margin-top: 50px">

			<el-col :span="8" class="text-center">
				{{yuyan.jiaojuan_name}}：{{userInfo.userName}}
			</el-col>

			<el-col :span="8" class="text-center">
				{{yuyan.jiaojuan_time}}：{{ paperData.userTime }}{{yuyan.fenzhong}}
			</el-col>

			<el-col :span="8" class="text-center">
				{{yuyan.jiaojuan_score}}：{{ paperData.userScore }} {{yuyan.fen}}
			</el-col>

		</el-row>

		<el-card style="margin: 40px;" :style="{textAlign:yuyan.now=='hanyu'?'left':'right'}" v-loading="loading">
			<div v-if="titleImg.length!==0">
				<el-image v-for="item in titleImg" style="height: 300px;width: auto;"
					:src="'https://admin.rolumapp.com/upload/'+item" fit="contain"></el-image>
			</div>
			<div v-for="item in paperData.quList">
				<div v-if="!item.isRight"  class="qu-content" >
					<p>{{ item.sort + 1 }}.{{ item.content }}（{{yuyan.defen}}：{{ item.actualScore }}）</p>
					<div v-if="item.quType === 1 || item.quType===3">
						<el-radio-group v-model="radioValues[item.id]">
							<el-radio disabled style="margin-right: 10px;" v-for="an in item.answerList" :label="an.id"> {{item.quType === 1?an.abc + `.`: ``}} {{an.content }} 
								<div v-if="item.image" style="display: flex;flex-wrap: wrap;">
									<el-image :preview-src-list="['https://admin.rolumapp.com/upload/'+item.image]"
										style="height: 150px;width: auto;" :src="'https://admin.rolumapp.com/upload/'+item.image"
										fit="contain"></el-image>
								</div>
							</el-radio>
						</el-radio-group>
						
						<el-row :gutter="24">
					
							<el-col :span="12" style="color: #24da70">
								{{yuyan.jiaojuan_yes}}：{{ item.quType == 3 ? (radioRights[item.id]=='A'?'✓':'✘'):radioRights[item.id] }}
							</el-col>
					
							<el-col v-if="!item.answered" :span="12" style="text-align: right; color: #ff0000;">
								{{yuyan.jiaojuan_no}}：{{yuyan.jiaojuan_no_write}}
							</el-col>
					
							<el-col v-if="item.answered && !item.isRight" :span="12"
								style="text-align: right; color: #ff0000;">
								{{yuyan.jiaojuan_no}}：{{  item.quType == 3 ? (myRadio[item.id]=='A'?'✓':'✘'):myRadio[item.id]}}
							</el-col>
					
							<el-col v-if="item.answered && item.isRight" :span="12"
								style="text-align: right; color: #24da70;">
								{{yuyan.jiaojuan_no}}：{{  item.quType == 3 ? (myRadio[item.id]=='A'?'✓':'✘'):myRadio[item.id]}}
							</el-col>
					
						</el-row>
					
					</div>
					
					<div v-if="item.quType === 4">
					
						<el-row :gutter="24">
					
							<el-col :span="12">
								{{yuyan.jiaojuan_my_answer}}：{{ item.answer }}
							</el-col>
					
						</el-row>
					
					</div>
					
					<div v-if="item.quType === 2">
						<el-checkbox-group v-model="multiValues[item.id]">
							<el-checkbox disabled style="margin-right: 10px;" v-for="an in item.answerList" :label="an.id"> {{ an.abc }}.{{ an.content}}
							<div v-if="item.image" style="display: flex;flex-wrap: wrap;">
								<el-image :preview-src-list="['https://admin.rolumapp.com/upload/'+item.image]"
									style="height: 150px;width: auto;" :src="'https://admin.rolumapp.com/upload/'+item.image"
									fit="contain"></el-image>
							</div>
							</el-checkbox>
						</el-checkbox-group>
					
						<el-row :gutter="24">
					
							<el-col :span="12" style="color: #24da70">
								{{yuyan.jiaojuan_yes}}：{{ multiRights[item.id].join(',') }}
							</el-col>
					
							<el-col v-if="!item.answered" :span="12" style="text-align: right; color: #ff0000;">
								{{yuyan.jiaojuan_no}}：{{yuyan.jiaojuan_no_write}}
							</el-col>
					
							<el-col v-if="item.answered && !item.isRight" :span="12"
								style="text-align: right; color: #ff0000;">
								{{yuyan.jiaojuan_no}}：{{ myMulti[item.id].join(',') }}
							</el-col>
					
							<el-col v-if="item.answered && item.isRight" :span="12"
								style="text-align: right; color: #24da70;">
								{{yuyan.jiaojuan_no}}：{{ myMulti[item.id].join(',') }}
							</el-col>
					
						</el-row>
					</div>
					
				</div>

			</div>

		</el-card>

	</div>
</template>

<script>
	import {
		paperResult
	} from '@/api/exam.js'

	export default {
		name: 'AuctionGoodsDetail',
		data() {
			return {
				titleImg: [],
				// 试卷ID
				paperId: '',
				yuyan: {},
				userInfo: JSON.parse(sessionStorage.userInfo),
				paperData: {
					quList: []
				},
				radioValues: {},
				multiValues: {},
				radioRights: {},
				multiRights: {},
				myRadio: {},
				myMulti: {},
				loading:true
			}
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},
		created() {
			this.yuyan = this.$language()
			const id = this.$route.query.id
			if (typeof id !== 'undefined') {
				this.paperId = id
				this.fetchData(id)
			}
		},
		methods: {

			fetchData(id) {
				const params = {
					id: id
				}
				paperResult(params).then(response => {
					// 试卷内容
					this.paperData = response
					if(this.paperData.images){
						this.titleImg = this.paperData.images
						this.titleImg = this.titleImg.split(',')
						if (this.titleImg[this.titleImg.length - 1] == "")
							this.titleImg.pop()
					}

					// 填充该题目的答案
					this.paperData.quList.forEach((item) => {
						let radioValue = ''
						let radioRight = ''
						let myRadio = ''
						const multiValue = []
						const multiRight = []
						const myMulti = []

						item.answerList.forEach((an) => {
							// 用户选定的
							if (an.checked) {
								if (item.quType === 1 || item.quType === 3) {
									radioValue = an.id
									myRadio = an.abc
								} else {
									multiValue.push(an.id)
									myMulti.push(an.abc)
								}
							}

							// 正确答案
							if (an.isRight) {
								if (item.quType === 1 || item.quType === 3) {
									radioRight = an.abc
								} else {
									multiRight.push(an.abc)
								}
							}
						})

						this.multiValues[item.id] = multiValue
						this.radioValues[item.id] = radioValue

						this.radioRights[item.id] = radioRight
						this.multiRights[item.id] = multiRight

						this.myRadio[item.id] = myRadio
						this.myMulti[item.id] = myMulti
					})
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
	.qu-content {

		border-bottom: #eee 1px solid;
		padding-bottom: 10px;

	}

	.qu-content div {
		line-height: 30px;
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
	}

	.card-title {
		background: #eee;
		line-height: 35px;
		text-align: center;
		font-size: 14px;
	}

	.card-line {
		padding-left: 10px
	}

	.card-line span {
		cursor: pointer;
		margin: 2px;
	}
</style>
